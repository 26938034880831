import { api } from '~/apis/back/services'
import type { RegionAzgoDTO } from '~/apis/back/types'

export function useCurrentCity() {
  const currentCity = useState('currentCity', () => null as RegionAzgoDTO | null)

  async function fetchCurrentCity() {
    const headers = useRequestHeaders(['x-forwarded-for', 'x-real-ip'])
    // 获取ip, 默认新加坡(454)
    const userIp = headers['x-forwarded-for'] || headers['x-real-ip'] || '454'

    const { data: cityData } = await api.regionRegionCurrentCityPost({ userIp })
    currentCity.value = cityData.value?.data || null
  }

  return {
    currentCity,
    fetchCurrentCity,
  }
}
